import { HttpResponse } from '@angular/common/http';

/*
		If there is an issues getting the filemame from the response header, make sure the expose header is set
		<expose-headers>
				<header>filename</header>
				<header>Content-Disposition</header>
		</expose-headers>
*/

export class FileData {

	constructor(public filename: string, public data: Blob) {
	}

	public static fromHttpResponse(response: HttpResponse<Blob>): FileData {
		const contentDisposition = response.headers.get('Content-Disposition');
		let filename = 'unknown.file';
		if (contentDisposition != null) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(contentDisposition);
			if (matches != null && matches[1]) {
				filename = matches[1];
			}
		}
		return new FileData(filename, response.body!);
	}
}
