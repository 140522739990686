import { FileData } from '@shared/models/FileData';
import { Observable, switchMap } from 'rxjs';



export function modifyFileData<T>(callback: (jsonData: T) => unknown, filename: string | null = null) {
	return switchMap((response: FileData) => {
		if (response.data === null) {
			throw new Error('Invalid response content type');
		}
		const reader = new FileReader();
		return new Observable<FileData>(subscriber => {
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					try {
						const jsonData = JSON.parse(reader.result);
						const modifiedData = callback(jsonData);
						const modifiedBlob = new Blob([JSON.stringify(modifiedData, null, 2)], { type: 'application/json' });
						const modifiedResponse = new FileData(filename ?? response.filename, modifiedBlob);
						subscriber.next(modifiedResponse);
					} catch (error) {
						subscriber.error(new Error('Invalid JSON format'));
					}
				} else {
					subscriber.error(new Error('Unexpected response format'));
				}
				subscriber.complete();
			};

			reader.onerror = error => {
				subscriber.error(error);
			};

			reader.readAsText(response.data);
		});
	});
}
