import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileData } from '../models/FileData';

// Create rxjs operator to create FileData from httpresponse<blob>

export function toFileData() {
	return (source: Observable<HttpResponse<Blob>>) => source.pipe(map(response => FileData.fromHttpResponse(response)));
}
